<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        <number-value :value="mass" unit="\text{g}" />
        of pure acenapthene (AN) is completed dissolved in
        <number-value :value="volume" unit="\text{mL}" />
        of hot ethanol at
        <stemble-latex content="$70^\circ\text{C.}$" />
        If this hot solution is then cooled in an ice bath, what quantity of AN would crystallize
        out of the cold ethanol at
        <stemble-latex content="$0^\circ\text{C?}$" />
      </p>

      <p class="mb-3 pl-8">
        <img src="/img/assignments/acenaphthene.png" style="max-width: 35%; height: auto" />
      </p>

      <calculation-input
        v-model="inputs.precipitate"
        prepend-text="$\text{Mass}:$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question406',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        precipitate: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
  },
};
</script>
